(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("BrandData"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["BrandData", "vue"], factory);
	else if(typeof exports === 'object')
		exports["init"] = factory(require("BrandData"), require("vue"));
	else
		root["init"] = factory(root["BrandData"], root["vue"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__670468__, __WEBPACK_EXTERNAL_MODULE__336256__) {
return 
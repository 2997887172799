import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import store from '@/store/store';
import { AlarmInDTO, AlarmDetailInDTO, AlarmSeverityDeviceDTO } from "@/interfaces/alarm";


class AlarmsAxiosInstance extends AbstractAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.alarmsApiUrl());
  }

  async getAlarms(params?: any): Promise<AlarmInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.post(`/search`, params);
  }

  async getAlarmDetails(alarmId): Promise<AlarmDetailInDTO> {
    return this.get('/' + alarmId);
  }

  async getAlarmsSeverityCount(): Promise<any> {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {
      params: {
        filter: `(status != "CLEARED" and (status == "ACTIVE" or ack == "REQUIRED" or reset == "REQUIRED") )`
      },
    };
    return this.get(`/severityCount?organization=${suborg}`, config);
  }

  getAlarmsSeverityCountForDevice({ pagination }): Promise<AlarmSeverityDeviceDTO> {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {
      params: {
        filter: `(status != "CLEARED" and (status == "ACTIVE" or ack == "REQUIRED" or reset == "REQUIRED"))`,
        pageSize: pagination.pageSize,
        next: pagination.next
      },
    };
    return this.get(`/severityCountByDevice?organization=${suborg}`, config);
  }

  async resetAlarm(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/reset?organization=${suborg}`, params);
  }

  async acknowledgeAlarm(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/ack?organization=${suborg}`, params);
  }

  async clearAlarm(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/clear?organization=${suborg}`, params);
  }

  async resetAllAlarms(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/resetAll?organization=${suborg}`, params );
  }

  async acknowledgeAllAlarms(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/ackAll?organization=${suborg}`, params );
  }

  async clearAllAlarms(params?: any) {
    const organizations = store.getters['permission/getOrganizations'];
    const suborg = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return this.post(`/action/clearAll?organization=${suborg}`, params );
  }
}

export default new AlarmsAxiosInstance();

import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import axios, { AxiosRequestConfig } from 'axios';
import { DashboardOutDTO, DashboardsInDTO, FetchDashboardParams, AssetStorageStatusDTO, OrganizationGalleryAsset} from "@/interfaces/dashboard";
import IDashboardAxiosInstance, { ProgressData } from "../IDashboardAxiosInstance";
import {PaginatedResponse, PageableFullDTO} from '../model/generic';
import store from "@/store/store";
import MockAxiosInstance from "./MockAxiosInstance";
import { FlowDTO } from "@/interfaces/flows";


export interface ResponseDashboardVersions extends PaginatedResponse
{
  data: [
    {
      name: string,
      description: string,
      creationDate: number,
      updatedAt: number,
      owner: string,
      autosave?: boolean,
    }
  ]
}

export interface UserPreferenceDashboardsDTO extends PageableFullDTO {
  content: {id: number, dashboardId: string}[];
}

interface OrganizationAssetManifest{
  main: {
    name: string,
    url: string
  },
  resources:{
    images: Array<{
      name: string
      url: string
    }>
  },
  source?: string
}

class DashboardAxiosInstance extends AbstractAxiosInstance implements IDashboardAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.dashboardApiUrl());
  }

  public createDashboard(data: DashboardOutDTO, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.post('/api/v1/dashboards', data, config);
  }

  public updateDashboard(data: DashboardOutDTO, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.patch('/api/v1/dashboards', data, config);
  }

  public fetchDashboards(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/dashboards', config);
  }

  public fetchWidgets(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.get('/api/v1/widgets', config);
  }

  public fetchFlows(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.get('/api/v1/flows', config);
  }

  public getDashboard(id: string, params?: any): Promise<DashboardOutDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    if ( id )
      return this.get('/api/v1/dashboards/' + id, config)
    return null;
  }

  public createFlow(data: FlowDTO, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.post('/api/v1/flows', data, config);
  }

  public updateFlow(data: FlowDTO, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.patch(`/api/v1/flows/${data.id}`, data, config);
  }
  
  public getFlow(id: string, params?: any): Promise<DashboardOutDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    if ( id ){
      //return MockAxiosInstance.get('/api/v1/dashboards/flow/'+id,config);
      return this.get(`/api/v1/flows/${id}`,config);
    }
    return null;
  }

  public deleteFlow(id: string, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.delete(`/api/v1/flows/${id}`, config)
  }

  public deleteDashboard(id: string, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.delete('/api/v1/dashboards/' + id, config)
  }

  public getMyDashboards(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.get('/api/v1/dashboards/mine', config);
  }

  public getMyFlows(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params: {
        ...params
      }
    };
    return this.get('/api/v1/flows/', config);
  }

  public getSharedDashboards(params?: FetchDashboardParams): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.get('/api/v1/dashboards/sharedWithMe', config);
  }

  public getDashboardVersions( id: string, params ): Promise<ResponseDashboardVersions> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/dashboards/' + id + '/versions', config);
  }

  public createDashboardVersion( id: string, data, params ) {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.post('/api/v1/dashboards/' + id + '/versions', data, config);
  }

  public getDashboardVersion( id: string, versionName: string, params ) {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/dashboards/' + id + '/versions/' + versionName, config);
  }

  public makeDashboardVersionCurrent( id: string, versionName: string, params )
  {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.post( '/api/v1/dashboards/' + id + '/versions/' + versionName + '/current', config );
  }

  public deleteDashboardVersion( id: string, versionName: string, params ) {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.delete('/api/v1/dashboards/' + id + '/versions/' + versionName, config);
  }

  public createDerivedDashboardVersion( id: string, oldVersionName: string, newVersionName: string, data, params ) {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.post('/api/v1/dashboards/' + id + '/versions/' + oldVersionName + '/versions/' + newVersionName, data, config);
  }

  public shareDashboard(dashboardId: string, groupName: string, permission: "r" | "r/w", params?: { organization: any }): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    
    if( permission === "r" )
      return this.post('/api/v1/dashboards/' + dashboardId + '/readers/' + groupName, undefined, config);
    else if( permission === "r/w" )
      return this.post('/api/v1/dashboards/' + dashboardId + '/editors/' + groupName, undefined, config);
  }

  public async shareDashboardWithUsers(dashboardId: string, listUsers: Array<{name: string, permission: string}>, params?: { organization: any }): Promise<void>
  {
    let readers = listUsers.filter( user => user.permission == "r" ).map( user => user.name );
    let editors = listUsers.filter( user => user.permission == "r/w" ).map( user => user.name );
    if ( readers.length > 0 ) {
      await this.post('/api/v1/dashboards/' + dashboardId + '/readers', { users: readers }, { params });
    }
    if ( editors.length > 0 ) {
      await this.post('/api/v1/dashboards/' + dashboardId + '/editors', { users: editors }, { params });
    }
    return Promise.resolve();
  }

  public async unshareDashboardWithUsers(dashboardId: string, listUsers:  Array<{name: string, permission: string}>, params?: { organization: any }): Promise<void> 
  { 
    let readers = listUsers.filter( user => user.permission == "r" ).map( user => user.name );
    let editors = listUsers.filter( user => user.permission == "r/w" ).map( user => user.name );
    let all = listUsers.filter( user => user.permission == undefined ).map( user => user.name );
    if(all.length > 0) {
      readers = readers.concat(all);
      editors = editors.concat(all);
    }
    if(editors.length > 0)
      await this.delete('/api/v1/dashboards/' + dashboardId + '/editors', { params, data: { users: editors } });
    if(readers.length > 0)
      await this.delete('/api/v1/dashboards/' + dashboardId + '/readers', { params, data: { users: readers } });
    return Promise.resolve();
  }

  public async unshareDashboard(dashboardId: string, groupName: string, params?: { organization: any }): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };
    await this.delete('/api/v1/dashboards/' + dashboardId + '/editors/' + groupName, config);
    await this.delete('/api/v1/dashboards/' + dashboardId + '/readers/' + groupName, config);
    return Promise.resolve();
  }

  public shareFlow(id: string, groupName: string, permission: "r" | "r/w", params?: { organization: any }): Promise<DashboardsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    
    if( permission === "r" )
      return this.post('/api/v1/flows/' + id + '/readers/' + groupName, undefined, config);
    else if( permission === "r/w" )
      return this.post('/api/v1/flows/' + id + '/editors/' + groupName, undefined, config);
  }

  public async shareFlowWithUsers(id: string, listUsers: Array<{name: string, permission: string}>, params?: { organization: any }): Promise<void>
  {
    let readers = listUsers.filter( user => user.permission == "r" ).map( user => user.name );
    let editors = listUsers.filter( user => user.permission == "r/w" ).map( user => user.name );
    if ( readers.length > 0 ) {
      await this.post('/api/v1/flows/' + id + '/readers', { users: readers }, { params });
    }
    if ( editors.length > 0 ) {
      await this.post('/api/v1/flows/' + id + '/editors', { users: editors }, { params });
    }
  }

  public async unshareFlowWithUsers(id: string, listUsers: Array<{name: string, permission: string}>, params?: { organization: any }): Promise<void> 
  {
    let readers = listUsers.filter( user => user.permission == "r" ).map( user => user.name );
    let editors = listUsers.filter( user => user.permission == "r/w" ).map( user => user.name );
    let all = listUsers.filter( user => user.permission == undefined ).map( user => user.name );
    if(all.length > 0) {
      readers = readers.concat(all);
      editors = editors.concat(all);
    }
    if(editors.length > 0)
      await this.delete('/api/v1/flows/' + id + '/editors', { params, data: { users: editors } });
    if(readers.length > 0)
      await this.delete('/api/v1/flows/' + id + '/readers', { params, data: { users: readers } });
  }

  public async unshareFlow(id: string, groupName: string, params?: { organization: any }): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };
    
    await this.delete('/api/v1/flows/' + id + '/editors/' + groupName, config);
    await this.delete('/api/v1/flows/' + id + '/readers/' + groupName, config);
  }

  public async addAsset( dashboardId: string, assetName: string, data: any, progressCallback: (ProgressData) => void = undefined, widgetId: string = undefined): Promise<boolean> {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const uploadLink = await this.get( 
      `/api/v1/dashboards/${dashboardId}/assets/${assetName}/upload`,
      { 
        params: {
          organization: organization.resourceId
        }
      }
    ) as { value: string };
    if ( uploadLink.value )
    {
      const config = {
        onUploadProgress: progressEvent => {
          if (progressCallback) {
            const pd:ProgressData = {
              name: assetName,
              total: progressEvent.total,
              loaded: progressEvent.loaded,
              percentageComplete: progressEvent.loaded / progressEvent.total,
              dashboardId: dashboardId,
              widgetId: widgetId,
            };
            progressCallback(pd);
          }
        } 
      }
      await axios.put( uploadLink.value, data, config );
      return true;
    }
    return false;
  };

  public async getAssetURL( dashboardId: string, assetName: string ): Promise<string>{
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const downloadLink = await this.get(
      `/api/v1/dashboards/${dashboardId}/assets/${assetName}/download`,
      { 
        params: {
          organization: organization.resourceId
        }
      }
    ) as { value: string };
    return downloadLink.value;
  }

  public async getAssetData( signedURL: string ): Promise<any>
  {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
    }
    return await axios.get( signedURL, config ).then( response => {
      return response.status == 200 ? response.data : null
    } );
  }

  public async getAssetStorageStatus(): Promise<AssetStorageStatusDTO>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.get(
      "/api/v1/dashboards/assetstorage",
      { 
        params: {
          organization: organization.resourceId
        }
      }
    );
  }

  public async getOrganizationAssetsManifest(): Promise<any>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.get(
      "/api/v1/organizations/manifest",
      { 
        params: {
          organization: organization.resourceId
        }
      }
    );
  }

  public async getOrganizationGalleryWidgets(): Promise<any>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.get(
      "/api/v1/organizations/gallery/widgets",
      { 
        params: {
          organization: organization.resourceId
        }
      }
    );
  }

  public async getOrganizationGalleryAsset( assetName ): Promise<OrganizationGalleryAsset>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const manifest: OrganizationAssetManifest = await this.get( 
      `/api/v1/organizations/gallery/assets/${assetName}/manifest`,
      {
        params: {
          organization: organization.resourceId
        }
      }
    );

    if ( manifest && manifest.main && manifest.main.url )
    {
      // Download library logic
      let lib = await axios.get( manifest.main.url ).then( response => response.status == 200 ? response.data : null );

      return {
        lib,
        url: manifest.main.url,
        asset: assetName,
        manifest
      }
    }
    return null;
  }

  public async uploadOrganizationGalleryAsset( assetName: string, file: File ): Promise<boolean>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const formData = new FormData();
    formData.append( "file", file );
    await this.put( 
      `/api/v1/organizations/gallery/assets/${assetName}/upload`,
      formData,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          organization: organization.resourceId
        }
      }
    );
    return true;
  }

  public async removeOrganizationGalleryAsset( assetName: string ): Promise<any>
  {
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return this.delete( 
      `/api/v1/organizations/gallery/assets/${assetName}`,
      {
        params: {
          organization: organization.resourceId
        }
      }
    );
  }

  public async fetchUserPreferences(username: string): Promise<UserPreferenceDashboardsDTO>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.get( `/api/v1/userPreferences?organization=${selectedOrganization.resourceId}&username=${username}` );
  }
  public async addDashboardToUserPreferences(username: string, dashboardId: string): Promise<any>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.post(`/api/v1/userPreferences?organization=${selectedOrganization.resourceId}&username=${username}`, {dashboardId: dashboardId} );
  }
  public async deleteDashboardFromUserPreferences(dashbordUserpreferenceId: number): Promise<any>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.delete( `/api/v1/userPreferences/${dashbordUserpreferenceId}?organization=${selectedOrganization.resourceId}`);
  }

}

export default new DashboardAxiosInstance();
import CommunicationSettings from "../../CommunicationSettings";
import AbstractAxiosInstance from "./AbstractAxiosInstance";
import IAxiosInstance from '../IAxiosInstance';
import IBasicAxiosInstance from '../IBasicAxiosInstance';
import { OrganizationInDTO } from '@/interfaces/organization';
import store from "@/store/store";

export interface CorvinaAxiosTokens {
  /** access_token  retrieved authenticating with keycloak */
  accessToken: any;
  RPTToken: any,
}

class BasicAxiosInstance extends AbstractAxiosInstance implements IBasicAxiosInstance {
  private corvina: CorvinaAxiosTokens = null;

  // Used to enqueue requests
  private genericTokenPromise: Promise<any>;
  private RPTTokenPromise: Promise<any>;
  private permission: string;

  constructor() {
    super();
    this.corvina = {
      accessToken: null,
      RPTToken: null,
    };
    this.RPTTokenPromise = null;
  }

  /**
   * Getter $corvina
   * @return {CorvinaAxiosTokens}
   */
  public get $corvina(): CorvinaAxiosTokens {
    return this.corvina;
  }

  /* Funnel multiple parallel calls */
  public async genericAccessToken(): Promise<string> {
    try {
      if (this.genericTokenPromise) {
        // someone is already requesting this token and the request is 
        // ongoing... avoid doing a new one
        return await this.genericTokenPromise;
      } else {
        this.genericTokenPromise = this._genericAccessToken();
        const result = await this.genericTokenPromise
        this.genericTokenPromise = null
        return result
      }
    } catch (err) {
      this.genericTokenPromise = null
      throw (err)
    }
  }

  public async _genericAccessToken(): Promise<string> {
    try {
      const keycloak = CommunicationSettings.$keycloak
      if (keycloak) {
        await keycloak.updateToken(30)
        this.corvina.accessToken = CommunicationSettings.$keycloak.token
        return this.corvina.accessToken;
      } else {
        console.warn("Cannot acquire token: are we logged in?")
      }
    } catch (axiosErr) {
      console.error("Error retrieving standard token!!")
      console.log(axiosErr)
      throw axiosErr;
    }
  }

  private async updateRPTToken(timeout) {
    if (!this.corvina.RPTToken)
      return;
    const validity = this.corvina.RPTToken.expire - Date.now() / 1000
    if (validity < timeout) {
      // the refresh token of token-exchange is not valid
      this.corvina.RPTToken = null;
    } else {
      //console.log(`RPTToken token not refreshed, valid for ${validity} seconds` )
    }
  }

  public async RPTPlatformToken(permission: string, options = { force: false }): Promise<string> {
    try {
      if (this.RPTTokenPromise && this.permission == permission) {
        // someone is already requesting this token and the request is 
        // ongoing... avoid doing a new one
        return await this.RPTTokenPromise;
      } else {
        if (this.permission && this.permission != permission) {
          // force invalidation if required permission (suborg) changed
          this.corvina.RPTToken = null
        }
        this.RPTTokenPromise = this._RPTPlatformToken(permission, options);
        this.permission = permission
        const result = await this.RPTTokenPromise
        this.RPTTokenPromise = null
        return result
      }
    } catch (err) {
      this.RPTTokenPromise = null
      throw (err)
    }
  }

  public async getAxiosInstanceToken(organization: OrganizationInDTO): Promise<string> {
    if (organization && organization.resourceId) {
      await this.RPTPlatformToken(organization.resourceId);
      return this.$corvina.RPTToken.access_token;
    } else {
      await this.genericAccessToken();
      return this.$corvina.accessToken;
    }
  }

  private async _RPTPlatformToken(permission: string, options = { force: false }): Promise<string> {
    // Try to update platform token if validity is going to expire
    await this.updateRPTToken(30);
    if (!options.force && this.corvina.RPTToken && this.corvina.accessToken == CommunicationSettings.$keycloak.token /* if parent token changed, invalidate also this one */) {
      console.log("reusing token...")
      return this.corvina.RPTToken.access_token;
    } else {
      if (options.force) {
        // a new access token is required to refresh the permissions contained in a RPT token after an update
        await CommunicationSettings.$keycloak.updateToken(-1);
      }
      // ensure access token is valid to request rpt token
      await this.genericAccessToken();

      let tokenEndpoint = CommunicationSettings.authApiUrl() + "/realms/" + CommunicationSettings.$keycloak.realm + "/protocol/openid-connect/token"

      try {
        var params = new URLSearchParams();
        params.append('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket');
        params.append('audience', 'corvina-platform');
        params.append('permission', permission);
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${this.corvina.accessToken}`
          }
        };
        const response = await this.post<any>(tokenEndpoint, params, config);
        const decode = (token: string) => {
          try {
            return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
          } catch (e) {
            console.warn("BasicAxiosInstance: cannot decode jwt token");
            return {};
          }
        };
        await store.dispatch('permission/setUserPermissions', {
          decoded: decode(response.access_token),
          access_token: response.access_token,
        });
        this.corvina.RPTToken = response;
        this.corvina.RPTToken.expire = Date.now() / 1000 + this.corvina.RPTToken.expires_in
        return this.corvina.RPTToken.access_token;
      } catch (axiosErr) {
        this.corvina.RPTToken = null;
        console.error("Error exchanging token!!")
        console.log(axiosErr)
        throw axiosErr;
      };
    }
  };

}

export default new BasicAxiosInstance() as IBasicAxiosInstance;

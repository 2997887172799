import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import BasicAxiosInstance from './BasicAxiosInstance';
import { Product, LicenseDTO, PageSubscriptionSummaryDTO, SubscriptionAggregatedOutputDTO } from "../model/Product";
import store from "@/store/store";

export interface ProductGetSubscriptionSummaryByOrgFilter {
  page: number
  pageSize: number
  organizationName?: string
  expirationDateFrom?: number
  expirationDateTo?: number
  activationDateFrom?: number
  activationDateTo?: number
  includeNotRedeemed?: boolean
}

class ProductsAxiosInstance extends AbstractAxiosInstance {

  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.licensesApiUrl());
  }

  public createProduct(product: Product): Promise<any> {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return this.post(`/products?orgResourceId=${selectedSubOrganization.resourceId}`, product)
  }

  public updateProduct(id: number, product: Product): Promise<any> {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return this.put(`/products/${id}?orgResourceId=${selectedSubOrganization.resourceId}`, product);
  }

  public deleteProduct(productId: string): Promise<any> {
    const config: AxiosRequestConfig = {
    //   params,
    };
    return this.delete('/products/' + productId, config)
  }

  public getProducts(productCode?: string, search?: string): Promise<any>
  {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const productCodeParams = productCode ? `&code=${productCode}` : '';
    const searchParams = search ? `&search=${search}` : '';
    return this.get(`/products?orgResourceId=${selectedSubOrganization.resourceId}&includeAllVersions=true&pageSize=10000${productCodeParams}${searchParams}`);
  }

  public async getAllProducts(productCode?: string): Promise<any>
  {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const productCodeParams = productCode ? `&code=${productCode}` : ''

    const products = [];
    let last = false;
    let args = { 
      pageSize: 200,
      page: 0
   }
    while(!last) {
      let url = `/products?orgResourceId=${selectedSubOrganization.resourceId}&includeAllVersions=true&${productCodeParams}`;
      for (let k in args) url += `&${k}=${args[k]}`;  
      const data = await this.get(url) as any;
      products.push(...data.content);
      last = data.last;
      args.page += 1;
    }
    return products;
  }

  public getSubscriptionSummary(orgId: string): Promise<any>
  {
    return this.get(`/subscriptions/summary?orgResourceId=${orgId}&includeExpired=true`);
  }

  public getSubscription(orgId: string): Promise<any>
  {
    return this.get(`/subscriptions?orgResourceId=${orgId}`);
  }

  public getSubscriptionAggregated(orgId: string): Promise<SubscriptionAggregatedOutputDTO[]>
  {
    return this.get(`/subscriptions/aggregated?orgResourceId=${orgId}`);
  }

  public getSubscriptionsSummaryByOrg(orgId: string, args:any): Promise<PageSubscriptionSummaryDTO>
  {
    let url = `/subscriptions/summaryByOrg?createdByOrgResourceId=${orgId}`;
    for (let i in args) {
      url += `&${i}=${args[i]}`;
    }  
    return this.get(url)
  }

  public getSubscriptionExpirationDate(licenseCode: string): Promise<any>
  {
    return this.get(`/licenses/expirationDate?licenseCode=${licenseCode}`);
  }

  public getOrganization(orgId: string): Promise<any> {
      return this.get('/organizations/' + orgId)
  }

  public putOrganization(orgId: string, dealer: boolean, hasOwnResources: boolean): Promise<any> {
    return this.put(`/organizations/${orgId}`, {"dealer": dealer, "hasOwnResources": hasOwnResources});
  }

  public getLicenses(orgResId: string, args: any): Promise<any>
  {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    orgResId = orgResId || selectedSubOrganization.resourceId
    let url = `/licenses?orgResourceId=${orgResId}`;
    for (let k in args) url += `&${k}=${args[k]}`;
    return this.get(url);
  }

  public async getAllLicenses(orgResId: string, args: any): Promise<any>
  {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    orgResId = orgResId || selectedSubOrganization.resourceId
    const licenses = [];
    let last = false;
    let args2 = { 
      pageSize: 200,
      orderBy: "creationDate",
      orderDir: "DESC",
      page: 0
   }
    while(!last) {
      let url = `/licenses?orgResourceId=${orgResId}`;
      for (let k in args2) url += `&${k}=${args2[k]}`;  
      const data = await this.get(url) as any;
      licenses.push(...data.content);
      last = data.last;
      args2.page += 1;
    }
    return licenses;
  }

  public getLicense(orgResId: string, licenseId: any): Promise<any>
  {
    let url = `/licenses/${licenseId}?orgResourceId=${orgResId}&`;
    return this.get(url);
  }

  public createLicense(license: LicenseDTO)
  {
    // TODO: check this is working when api is updated to accept orgResourceId
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return this.post(`/licenses?orgResourceId=${selectedSubOrganization.resourceId}`, license);
  }

  public deleteLicense(licenseId: string): Promise<any> {
    const config: AxiosRequestConfig = {
    //   params,
    };
    return this.delete('/licenses/' + licenseId, config)
  }

  public updateLicenseAutorenew(licenseId: number, autoRenew: boolean): Promise<any>
  {
    return this.put(`/licenses/${licenseId}`, {"autorenew": autoRenew});
  }

  public renewLicense(licenseId: number): Promise<any>
  {
    return this.post(`/licenses/${licenseId}/renew`, {});
  }

  public activateLicense(orgId: string, licenseCode: string): Promise<any>
  {
    const data = { licenseCode: licenseCode, orgResourceId: orgId};
    return this.post('/licenses/activation', data);
  }

  public licensesCount(): Promise<any>
  {
    const selectedSubOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const config:AxiosRequestConfig = {
        params: { pageSize: 1000 }
      };
    return this.get(`/products/count?orgResourceId=${selectedSubOrganization.resourceId}`, config);
  }

  public getProduct(id: number): Promise<any>
  {
    const p = this.get('/products/' + id);
    return p;
  }

  public async DBGPrintToken()
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedOrganization;
    const token = await BasicAxiosInstance.getAxiosInstanceToken(selectedOrganization);
    console.log('token', token);
  }

  public getTrialExpiration(orgId: string): Promise<number>
  {
    return this.get(`/subscriptions/trialExpiration?orgResourceId=${orgId}`);
  }

}

export default new ProductsAxiosInstance();
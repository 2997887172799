import * as KeyCode from "keycode-js";
import { getModule } from "vuex-module-decorators";
import store from "@/store/store";

import configurationShortcuts from "./Settings";

enum ShortcutContext {
  DASHBOARD_EDITOR = "dashboardEditor",
  FRAME = "frame"
}

class KeyboardShortcutsMgr {
  static instance: KeyboardShortcutsMgr;
  projectStore: any;

  constructor() {
    document.addEventListener("keydown", (event) => this.handleShortcut(event));
  }

  public async handleShortcut(event: KeyboardEvent) {
    let context = await this.identifyContext(event);
    switch (context) {
      case ShortcutContext.DASHBOARD_EDITOR:
        this.handleDashboardKeyDown(event);
        break;
      default:
        this.handleFrameKeyDown(event);
    }
  }

  private async identifyContext(event: any /*KeyboardEvent*/): Promise<ShortcutContext> {
    let path = event.composedPath();
    if (this.isDashboardEditorOpen() && this.noComponentSelected(path)) {
      // Load lazy project store
      if (this.projectStore == null) {
        this.projectStore = getModule(((await import("@/store/Project-store")).default));
      }
      return ShortcutContext.DASHBOARD_EDITOR;
    }
    return null;
  }

  private isDashboardEditorOpen(): boolean {
    return window.location.hash.includes("dashboards") && (window.location.hash.includes("edit") || window.location.hash.includes("new"));
  }

  private noComponentSelected(path): boolean {
    if (path && path.length > 0) {
      return path[0] instanceof Element && (path[0].localName == "body" || path[0].localName == "canvas" || (path[0].classList && path[0].classList.contains("toolbar-icon")));
    }
    return false;
  }

  private checkShortcut(operation: string, event: KeyboardEvent): boolean {
    const keys = configurationShortcuts[operation];
    if (!keys || keys.length == 0) {
      return false;
    }
    let result = true;
    for (let key of keys) {
      if (typeof key == "string") {
        result = result && event[key];
      } else if (typeof key == "number") {
        result = result && event.keyCode == key;
      }
    }
    return result;
  }

  private handleDashboardKeyDown(e: KeyboardEvent) {
    if (this.checkShortcut("dashboardEditor.widgetCopy", e)) this.copy(e);
    else if (this.checkShortcut("dashboardEditor.widgetPaste", e)) this.paste(e);
    else if (this.checkShortcut("dashboardEditor.widgetCut", e)) {
      let selectedWidget = this.projectStore.getSelectedWidget;
      if (selectedWidget) {
        this.copy(e);
        this.projectStore.removeWidget({
          widgetId: selectedWidget.wgtId,
          record: true,
        });
      }
    } else if (this.checkShortcut("dashboardEditor.widgetRemovePC", e) || this.checkShortcut("dashboardEditor.widgetRemoveMC", e)) {
      let selectedWidget = this.projectStore.getSelectedWidget;
      if (selectedWidget) {
        if (selectedWidget.type == "PageWgt" || selectedWidget.name == "GroupWgt0") return;
        this.projectStore.removeWidget({
          widgetId: selectedWidget.wgtId,
          record: true,
        });
      }
    } else if (e.keyCode == KeyCode.KEY_ESCAPE) {
      this.projectStore.sendKeyboard(e);
    }

    if (!e.shiftKey && e.ctrlKey && e.keyCode == 90) {
      this.projectStore.undo();
    }

    if (e.shiftKey && e.ctrlKey && e.keyCode == 90) {
      this.projectStore.redo();
    }
  }

  // Function from PageWgt.vue
  private async copy(evt) {
    await this.projectStore.copy();
    // We ask for write permissions to the clipboard and wait for result.
    let result;
    try {
      result = await (<any>navigator).permissions.query({ name: "clipboard-write" });
    } catch (e) {
      // Firefox not support clipboard-write and generate an expection
    }
    // We check the result if "granted" or "prompt" we can write.
    if (result && (result.state == "granted" || result.state == "prompt")) {
      // We save a string with the serialization of the copied widget
      navigator.clipboard.writeText(JSON.stringify(this.projectStore.getCopiedWidget));
    }
  }

  private handleFrameKeyDown(e){
    if (this.checkShortcut("frame.openPortalMenu", e)){
      store.commit("frame/TOGGLE_PORTAL_MENU");
    }
  }

  // Function from PageWgt.vue
  private paste(evt) {
    this.projectStore.paste();
  }

  // Return singleton for this class
  public static getInstance(): KeyboardShortcutsMgr {
    if (KeyboardShortcutsMgr.instance == null) {
      KeyboardShortcutsMgr.instance = new KeyboardShortcutsMgr();
    }
    return KeyboardShortcutsMgr.instance;
  }
}

export default KeyboardShortcutsMgr;

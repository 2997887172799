import AbstractAxiosInstance from "./AbstractAxiosInstance";
import ICorvinaPlatformController from "../ICorvinaPlatformControllerInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import store from "@/store/store";

import {
  PlatformTagsDTO, PlatformDeviceStatusDTO, DeviceTagsParams, GeneralParams, DeviceWriteTagsParams,
  DeviceConfigurationDTO, DeviceTagInfoDTO, DeviceDetailsDTO, DeviceStatusDTO, PlatformQueryDataDTO
} from "@/interfaces/IPlatformController";
import { DeviceDetails } from "@/interfaces/devicedetails";
import { ModelMarker } from "@/interfaces/preset";

class CorvinaPlatformControllerInstance extends AbstractAxiosInstance implements ICorvinaPlatformController {
  
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.platformControllerUrl());
  }

  public getAvailableTags(organizationId: string, deviceId: string): Promise<Array<DeviceTagInfoDTO>> {
    return this.get(`/organizations/${organizationId}/devices/${deviceId}/availableTags`)
      .then((response: { data: string }) => response.data && JSON.parse(response.data));
  }

  public setTagsByDeviceId(organizationId: string, deviceId: string, data: DeviceWriteTagsParams): Promise<any> {
    return this.post(`/organizations/${organizationId}/devices/${deviceId}/tags`, data);
  }

  public getTagsByDeviceId(organizationId: string, deviceId: string, params: DeviceTagsParams): Promise<Array<PlatformTagsDTO>> {
    const config: AxiosRequestConfig = { params: params };
    return this.get(`/organizations/${organizationId}/devices/${deviceId}/tags`, config);
  }

  public async fetchTagsByDeviceId(organizationId: string, deviceId: string, params: DeviceTagsParams): Promise<Response> {
    const baseURL = CommunicationSettings.platformControllerUrl();
    let query = [];
    for (let key in params) {
      query.push(`${key}=${encodeURIComponent(params[key])}`)
    }
    const url = `${baseURL}/organizations/${organizationId}/devices/${deviceId}/tags` + (query.length > 0 ? `?${query.join("&")}` : "");
    return fetch(
      url,
      {
        headers: {
          'Authorization': store.getters['permission/getAccessToken']
        }
      }
    );
  }

  public queryTags(organizationId: string, params: PlatformQueryDataDTO): Promise<Array<PlatformTagsDTO>> {
    const config: AxiosRequestConfig = { params: params };
    return this.post(`/organizations/${organizationId}/devices/tags/query`, config);
  }

  public queryTagsDefinition(organizationId: string, params: PlatformQueryDataDTO) {
    const url = CommunicationSettings.platformControllerUrl();
    const endpoint = `/organizations/${organizationId}/devices/tags/query`;
    return {
      method: 'POST',
      url: url + endpoint,
      params: params,
    }
  }

  public getTagsByDeviceName(organizationId: string, deviceName: string, params: DeviceTagsParams): Promise<Array<PlatformTagsDTO>> {
    const config: AxiosRequestConfig = { params: params };
    return this.get(`/organizations/${organizationId}/devicesByName/${deviceName}/tags`, config);
  }

  public getTagsByDeviceGroup(organizationId: string, deviceGroup: string, params: DeviceTagsParams): Promise<Array<PlatformTagsDTO>> {
    const config: AxiosRequestConfig = { params: params };
    return this.get(`/organizations/${organizationId}/deviceGroups/${deviceGroup}/tags`, config);
  }

  public getDeviceStatus(organizationId: string, deviceId: string, params: GeneralParams): Promise<Array<PlatformDeviceStatusDTO>> {
    const config: AxiosRequestConfig = { params: params };
    return this.get(`/organizations/${organizationId}/devices/${deviceId}/status`, config);
  }

  public getDeviceConfiguration(organizationId: string, deviceId: string): Promise<DeviceConfigurationDTO> {
    return this.get(`/organizations/${organizationId}/devices/${deviceId}/configuration`);
  }

  public getDevicesDetails( organizationId: string ): Promise<Array<DeviceDetails>>
  {
    return this.get( `/organizations/${organizationId}/devices?details=true` )
          .then( ( response: DeviceDetailsDTO ) => response.data );
  }

  public getDevicesByAlias( organizationId: string, deviceAlias: string ): Promise<DeviceStatusDTO>
  {
    return this.get( `/organizations/${organizationId}/deviceByAlias`, { params: { deviceAlias: deviceAlias } } )
      .then( ( response: { data: DeviceStatusDTO } ) => response.data );
  }

  public async resyncDeviceStatus(organizationId: string, deviceId: string) {
    return this.get(`/organizations/${organizationId}/devices/${deviceId}/status/sync`)
  }

  public async getDeviceConnectionDetails(organizationId: string, deviceLogicalId: string, since: number = 0, to: number = 0, limit: number = 1000) {
    let config = {};
    if (since || to) {
      config = {
        params: {
          since,
          to,
          limit
        }
      }
    } else {
      config = {
        params: {
          limit
        }
      }
    }
    return this.get(`/organizations/${organizationId}/devices/${deviceLogicalId}/status`, config);
  }


  public async fetchDeviceConnectionDetails(organizationId: string, deviceId: string, params: GeneralParams): Promise<Response> {
    const baseURL = CommunicationSettings.platformControllerUrl();
    let query = [];
    for (let key in params) {
      query.push(`${key}=${encodeURIComponent(params[key])}`)
    }
    const url = `${baseURL}/organizations/${organizationId}/devices/${deviceId}/status` + (query.length > 0 ? `?${query.join("&")}` : "");
    return fetch(
      url,
      {
        headers: {
          'Authorization': store.getters['permission/getAccessToken']
        }
      }
    );
  }

  public async validatePresetFormula(data: { formula:  { functions: { formula: { map: String } } } }): Promise<Array<ModelMarker>> {
    return this.post('/preset/formula/validate', data);
  }
}

export default new CorvinaPlatformControllerInstance();
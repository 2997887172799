import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import store from '@/store/store';


class ThemeAxiosInstance extends AbstractAxiosInstance {

  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.themeApiUrl());
  }

  async getTheme(organizationId): Promise<any>
  {
    return this.get(`/${organizationId}/theme`);
  }

  async getThemes(host): Promise<any>
  {
    // TODO: tentative fix for theme desync
    // if(this.requestInterceptor != -1) 
    //   this.ejectRequestInterceptor(this.requestInterceptor);

    return host ? this.get(`/theme`, { params: { host } }) : this.get(`/theme`);
  }

  async saveTheme(organizationId, theme): Promise<any>
  {
    return this.put(`/${organizationId}/theme`, theme);
  }
} 

export default new ThemeAxiosInstance();
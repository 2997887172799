interface FrameState {
  topbar: boolean,
  topbarSize: {
    height: number
  },
  panels: Array<Panel>,
  path: string,
  loading: boolean,
  portalMenu: {
    status: "open" | "closed"
  }
}

type PanelType = "overlay" | "docked";
type DockLocation = "left" | "";

interface Panel {
  ref: string,
  type: PanelType,
  position: {
    x: number,
    y: number,
  },
  size: {
    width: string,
    height: string
  },
  dock: {
    location: DockLocation
  }
}

export default {
  namespaced: true,
  state: (): FrameState => ({
    topbar: false,
    topbarSize: {
      height: 50
    },
    panels: [],
    path: "/",
    loading: true,
    portalMenu: {
      status: "closed"
    }
  }),
  mutations: {
    TOGGLE_PORTAL_MENU(state: FrameState, panel: Panel) {
      state.portalMenu.status = state.portalMenu.status == "open" ? "closed" : "open";
    },
    SHOW_TOPBAR(state: FrameState) {
      state.topbar = true;
    },
    HIDE_TOPBAR(state: FrameState) {
      state.topbar = false;
    },
    SET_CURRENT_ROUTE(state: FrameState, path: string ) {
      state.path = path;
    },
    SET_LOADING_STATUS( state: FrameState, status: boolean ) {
      state.loading = status;
    },
    ADD_PANEL(state: FrameState, panel: Panel) {
      for(let panel of state.panels){
        if(panel.ref == panel.ref)
          return;
      }
      state.panels = [...state.panels, panel];
    },
    REMOVE_PANEL(state: FrameState, panel: {ref: string}) {
      for(let i = 0; i < state.panels.length; i++){
        if(state.panels[i].ref == panel.ref){
          state.panels.splice(i, 1);
          state.panels = [...state.panels];
          return;
        }
      }
    },
    UPDATE_PANEL(state: FrameState, panel: Panel) {
      for(let p of state.panels){
        if(p.ref == panel.ref){
          if(panel.type)
            p.type = panel.type;
          if(panel.position)
            p.position = panel.position;  
          if(panel.size)
            p.size = panel.size;
          if(panel.dock)
            p.dock = panel.dock;
          state.panels = [...state.panels];
          return;
        }
      }
    }
  },
  actions: {
    showTopbar(context) {
      context.commit('SHOW_TOPBAR')
    },
    hideTopbar(context) {
      context.commit('HIDE_TOPBAR')
    },
    setCurrentRoute( context, { route } ) {
      context.commit( 'SET_CURRENT_ROUTE', route )
    },
    showLoader( context ){
      context.commit( 'SET_LOADING_STATUS', true );
    },
    hideLoader( context ){
      context.commit( 'SET_LOADING_STATUS', false );
    }
  },
  getters: {
    topbarVisible(state: FrameState) {
      return state.topbar;
    },
    route(state: FrameState) {
      return state.path;
    },
    loading(state: FrameState) {
      return state.loading;
    },
    panels(state: FrameState) {
      return state.panels;
    },
    topBarHeight(state: FrameState) {
      return state.topbarSize.height;
    },
    portalMenuStatus(state: FrameState) {
      return state.portalMenu.status;
    }
  }
}
import IAbilityProvider from './../IAbilityProvider';
import IAbilityHelper from './../IAbilityHelper';
import AbilityHelper from './AbilityHelper';

class AbilityProvider implements IAbilityProvider {
  
  private rootCompanyAbilityHelper: IAbilityHelper = new AbilityHelper();

  getRootCompanyAbilityHelper() : IAbilityHelper {
    return this.rootCompanyAbilityHelper;
  }
}

export default new AbilityProvider();

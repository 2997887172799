import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import BasicAxiosInstance from './BasicAxiosInstance';
import store from '@/store/store';
import { NotificationIn, NotificationInDTO } from "@/interfaces/notification";


class NotificationAxiosInstance extends AbstractAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.notificationApiUrl());
  }

  async createNotification(notification: NotificationIn): Promise<any> {
    const organizations = store.getters['permission/getOrganizations'];
    const subOrgResId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {  
      params: {
        organization: subOrgResId,
      }
    };
    return this.post('', notification, config);
  }

  async getNotifications(params?: any): Promise<NotificationInDTO> {
    const organizations = store.getters['permission/getOrganizations'];
    const subOrgResId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {  
      params: {
        organization: subOrgResId,
        ...params
      }
    };

    return this.get('/', config);
  }

  async getNotification(id: number, params?: any): Promise<NotificationIn> {
    const organizations = store.getters['permission/getOrganizations'];
    const subOrgResId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {  
      params: {
        organization: subOrgResId,
        ...params
      }
    };

    return this.get(`/${id}`, config);
  }

  async deleteNotification(id: number, params?: any) : Promise<any> {
    const organizations = store.getters['permission/getOrganizations'];
    const subOrgResId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {  
      params: {
        organization: subOrgResId,
        ...params
      }
    };

    return this.delete(`/${id}`, config);
  }

  async updateNotification(id: number, notification: NotificationIn): Promise<any> {
    const organizations = store.getters['permission/getOrganizations'];
    const subOrgResId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config: AxiosRequestConfig = {  
      params: {
        organization: subOrgResId,
      }
    };
    return this.put(`/${id}`, notification, config);
  }
}

export default new NotificationAxiosInstance();
import AbstractAxiosInstance from './AbstractAxiosInstance';
import CommunicationSettings from '../../CommunicationSettings';
import BasicAxiosInstance from './BasicAxiosInstance';
import { AxiosRequestConfig } from 'axios';
import store from '@/store/store';
import { ResourceLimit, ResourceLimitOutDTO } from '../model/Product';


class LimitsAxiosInstance extends AbstractAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }


  public fetchLimits(orgResId: string): Promise<ResourceLimitOutDTO[]>
  {
    return this
      .get<any>(`${CommunicationSettings.limitsApiUrl()}?orgResourceId=${orgResId}`)
      .then(res => res.content);
  }

  public createLimits(limits: ResourceLimit | ResourceLimit[]): Promise<any>
  {
    if (limits === undefined) throw new Error('undefined limits');
    let ll: ResourceLimit[];
    if (!(limits as ResourceLimit[]).length) ll = [<ResourceLimit>limits];
    else ll = <ResourceLimit[]>limits;

    const promises = ll.map(l => this.post<any>(`${CommunicationSettings.limitsApiUrl()}`, l));

    return Promise.all<any>(promises);
  }

  public createLimit(limit: ResourceLimit)
  {
    return this.post<any>(`${CommunicationSettings.limitsApiUrl()}`, limit);
  }

  public updateLimit(limit: ResourceLimit)
  {
    return this.put<any>(`${CommunicationSettings.limitsApiUrl()}`, limit);
  }
}

export default new LimitsAxiosInstance();
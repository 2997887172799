import { getModule } from "vuex-module-decorators";
import store from "./store";

export namespace StoreLoader
{
  export async function loadPermissionStores()
  {
    !store.hasModule( "permission" ) && await import('./Permission-store')
      .then(PermissionStore => {
        store.registerModule('permission', PermissionStore.default)
      });
  }

  export async function loadVPNStores()
  {
    // await loadPermissionStores();
    // !store.hasModule( "vpn" ) && await import('./VPN-store')
    //   .then(VPNStore => {
    //     store.registerModule( 'vpn', VPNStore.default)
    //   });

    // !store.hasModule( "vpnapp" ) && await import('./VPNApp-store')
    //   .then(VPNAppStore => {
    //     store.registerModule( 'vpnapp', VPNAppStore.default)
    //   });
  }


  export async function loadThemeStores() {
    !store.hasModule( "theme" ) && await import('./Theme-store')
      .then(async ThemeStore => {
        store.registerModule( 'theme', ThemeStore.default );

        //console.log('store', this.$store);
        await store.dispatch('theme/initState').then(values => {
          for (let name in values) {
            document.documentElement.style.setProperty('--' + name, values[name]);
          }
          // TODO: inject colors into vuetify theme if possible
          // only some colors seems to be customizable via variable:
          // https://vuetifyjs.com/en/customization/sass-variables/
          // https://vuetifyjs.com/en/styles/colors/
          // the majority seems to be compiled sass e.g. theme.light.text.primary that defaults to black 0.87 alpha
  
          // if colors are injected they cannot be changed afterwards:
          // when selecting a suborg with a differente theme they do not update
          // console.log("App.vue theme colors", values)
          // this.$vuetify.theme.themes.light.primary = values['color-highlight']
          // this.$vuetify.theme.themes.light.error = values['color-alert']
          // console.log("vuetify light theme colors", this.$vuetify.theme.themes.light)
        });


        // load theme as soon as possible
        await store.dispatch("theme/loadTheme");
      });
  }

  export async function loadFrameStores()
  {
    loadBaseUIStores();
    !store.hasModule( "error" ) && await import('./Error-store')
      .then(ErrorStore => {
        store.registerModule( 'error', ErrorStore.default )
      });

    !store.hasModule( "loadingBar" ) && await import('./LoadingBar-store')
      .then(LoadingBarStore => {
        store.registerModule( 'loadingBar', LoadingBarStore.default )
      });

    !store.hasModule( "sorting" ) && await import('./Sort-store')
      .then(SortingStore => {
        store.registerModule( 'sorting', SortingStore.default) 
      });

    // !store.hasModule( "limits" ) && await import('./Limits-store')
    //   .then(LimitsStore => {
    //     store.registerModule( 'limits', LimitsStore.default) 
    //   });

    !store.hasModule( "product" ) && await import('./Product-store')
    .then(ProductStore => {
      store.registerModule( 'product', ProductStore.default )
    });

    !store.hasModule( "alarms" ) && await import('./Alarms-store')
    .then(AlarmsStore => {
      store.registerModule( 'alarms', AlarmsStore.default) 
    });

    !store.hasModule( "device" ) && await import('./Device-store')
    .then(DeviceStore => {
      store.registerModule( 'device', DeviceStore.default )
    });
  }


  export async function loadDeviceStores()
  {
    await loadFrameStores();

    !store.hasModule( "devicemap" ) && await import('./Devicemap-store')
      .then(DeviceMapStore => {
        store.registerModule( 'devicemap', DeviceMapStore.default )
      });

    !store.hasModule( "device" ) && await import('./Device-store')
      .then(DeviceStore => {
        store.registerModule( 'device', DeviceStore.default )
      });

    !store.hasModule( "walkthrough" ) && await import('./Walkthrough-store')
      .then(WalkthroughStore => {
        store.registerModule( 'walkthrough', WalkthroughStore.default )
      });

    !store.hasModule( "product" ) && await import('./Product-store')
      .then(ProductStore => {
        store.registerModule( 'product', ProductStore.default )
      })
  }

  export async function loadNotificationStores(){
    await loadDeviceStores();
    !store.hasModule( "alarms" ) && await import('./Alarms-store')
      .then(AlarmsStore => {
        store.registerModule( 'alarms', AlarmsStore.default) 
      });
    
    !store.hasModule( "notifications" ) && await import('./Notification-store')
      .then(NotificationStore => {
        store.registerModule( 'notifications', NotificationStore.default) 
      });
  }

  export async function loadAuditStores() {
    await loadDeviceStores();
    !store.hasModule( "audit" ) && await import('./Audit-store')
      .then(AuditStore => {
        store.registerModule( 'audit', AuditStore.default )
      });
  }

  export async function loadBaseUIStores() {
    await loadPermissionStores();
    await loadThemeStores();
    !store.hasModule( "modal" ) && await import('./Modal-store');
  }

  export const loadDashboardsStores = once(async function(){
    await loadPermissionStores();
    await loadVPNStores();
    await loadFrameStores();
    await loadDeviceStores();
    await loadNotificationStores();
  })
  
  //Load trigger dependent store then load trigger store
  export const loadTriggerStores = once(async function(){
    await loadDeviceStores();
    !store.hasModule( "trigger" ) && await import('./Trigger-store')
      .then(TriggerStore => {
        store.registerModule( 'trigger', TriggerStore.default )
      });
  })
  
  export async function loadAllStores()
  {
    await loadDashboardsStores();
  }

  function once (asyncFunction: Function) {
    const status = { promise: null, reject: null, resolve: null };
    return async ()=>{
      if(status.promise) return status.promise;
      status.promise = new Promise((resolve, reject)=>{
        status.resolve = resolve;
        status.reject = reject;
      });
      await asyncFunction();
      status.resolve();
    }
  }
}
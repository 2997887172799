enum PermissionEntity {
    USERS = 'IAM.USERS',
    USERGROUPS = 'IAM.USERGROUPS',
    ROLES = 'IAM.ROLES',
    DEVICES = 'IAM.DEVICES',
    DEVICEGROUPS = 'IAM.DEVICEGROUPS',
    ORGANIZATIONS = 'IAM.ORGANIZATIONS',
    MODELS = 'IAM.MODELS',
    DASHBOARDS = 'IAM.DASHBOARDS',
    PRODUCTS = 'IAM.PRODUCTS',
    NOTIFICATIONS = 'IAM.NOTIFICATIONS',
    ALARMS = 'IAM.ALARMS',
    PORTAL = 'IAM.PORTAL',
    AUDIT = 'IAM.AUDIT',
}

export default PermissionEntity;
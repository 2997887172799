// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { appFeatureBlacklist, appFeatureWhitelist, appFeatureEnabled } from "BrandData";

export interface IAppCustomizations {
  groupPoliciesDisabled: boolean;
  showMapByDefault: boolean;
  hideDeviceSerialNumber: boolean;
  hideDeviceDescription: boolean;
  hideVpnExportConfig: boolean;
}

export interface IAppFeatures {
  structs: boolean;
  highFreq: boolean;
  deLanguage: boolean,
  esLanguage: boolean,
  frLanguage: boolean,
  jpLanguage: boolean,
  developerUI: boolean;
  formulaMapping: boolean;
  developerUI_DEFreeGridInfo: boolean;
  ipFilter: boolean;
  loginSplashVideoBackground: boolean;
  editorInspector: boolean;
  dataTable: boolean;
  VPNnetworkSize: boolean;
  showVpnIp: boolean;
  barChartTimeDrill: boolean;
  deviceServiceAccountAssociation: boolean;
  dashboardClocks: boolean;
}

export const AppFeatures: IAppFeatures & IAppCustomizations = {
  structs: false,
  highFreq: false,
  deLanguage: false,
  esLanguage: false,
  frLanguage: false,
  jpLanguage: false,
  developerUI: false,
  formulaMapping: false,
  developerUI_DEFreeGridInfo: false,
  ipFilter: false,
  loginSplashVideoBackground: false,
  editorInspector: false,
  dataTable: true,
  VPNnetworkSize: false,
  showVpnIp: false,
  barChartTimeDrill: false,
  deviceServiceAccountAssociation: false,
  dashboardClocks: false,
  groupPoliciesDisabled: false,
  showMapByDefault: false,
  hideDeviceSerialNumber: false,
  hideDeviceDescription: false,
  hideVpnExportConfig: false
};
// Exclude from enable/disable all
const featuresNotManagedByShortcut = [ "groupPoliciesEnabled" ];

const prefix = "corvinaAppFeatures";
const featureKey = (name: string) => `${prefix}.${name}`;
const getFeature = (name: string) => {
  return localStorage[featureKey(name)] == "true";
};

const setFeature = (name: string, enabled: boolean) => {
  localStorage[featureKey(name)] = enabled;
  AppFeatures[name] = enabled;
};

const getAll = () => {
  for (const feat in AppFeatures) {
    AppFeatures[feat] = getFeature(feat);
  }
};

const setAll = (enabled: boolean) => {
  for (const feat of availableFeatures()) {
    if (!enabled || !featuresNotManagedByShortcut.includes(feat)) {
      setFeature(feat, enabled);
    }
  }
};

function showConfirmationModal() {
    return new Promise((resolve, reject) => {
      import('@/corvina-model').then( ( {ModalEnum} ) => {

        import('@/store/store').then( ( {default: store} ) => {
          store.dispatch("modal/showModal", {
              id: "customModal",
              title: "Experimental features",
              message: "Select experimental features to enable/disable?",
              type: "FeatureSelection",
              onSuccess: (data) => {
                resolve(data);
                store.dispatch("modal/hideModal", ModalEnum.CUSTOM_MODAL);
              },
              onClose: () => {
                store.dispatch("modal/hideModal", ModalEnum.CUSTOM_MODAL);
                reject();
              }
          });
        });
      });
  });
}

/** Returns the editable features for this brand (or already saved in local storage)*/
export function availableFeatures() {

  let features = Object.keys(AppFeatures);

  if (appFeatureWhitelist) {
    features = features.filter((feature) => appFeatureWhitelist.includes(feature) || localStorage[featureKey(feature)]);
  }

  if (appFeatureBlacklist) {
    features = features.filter((feature) => (!appFeatureBlacklist.includes(feature)) || localStorage[featureKey(feature)]);
  }

  return features;
}

export function initAppFeatures() {
  // load from local storage
  getAll();

  // inject defaults from brand
  if (appFeatureEnabled) {
    for(let f of appFeatureEnabled) {
      if (localStorage[featureKey(f)] == undefined) {
        AppFeatures[f] = true;
      }
    }
  }

  document.addEventListener(
    "keyup",
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.altKey && e.shiftKey) {
        if (e.code == "KeyE") {
          // call your function to do the thing
          if (
            window.confirm("Do you want to enable all experimental feature?")
          ) {
            setAll(true);
            location.reload();
          }
        } else if (e.code == "KeyD") {
          if (
            window.confirm("Do you want to disable all experimental feature?")
          ) {
            setAll(false);
            location.reload();
          }
        } else if (e.code == "KeyS") {
          showConfirmationModal().then((data: any) => {
            setAll(false);
            for(let f in data.selectedFeatures) {
              setFeature(data.selectedFeatures[f], true);
            }
            location.reload();
          }).catch( (e) => {
          });
        }
      }
    },
    false
  );
}

export enum ClientOs {
  UNKNOWN = "",
  WINDOWS = "Win",
  LINUX = "Lin",
  MAC = "Mac",
  ANDROID = "Android",
  IOS = "iOS"
}

var browserOSCached: ClientOs = undefined;

export async function browserOS(): Promise<ClientOs> {
  if (browserOSCached === undefined) {
    let platform = "";
    try {
      platform = (
        await (window.navigator as any).userAgentData.getHighEntropyValues([
          "platform"
        ])
      ).platform;
    } catch (e) {
      platform = window.navigator.platform;
    }
    if (/win/i.test(platform)) {
      browserOSCached = ClientOs.WINDOWS;
    } else if (/linux/i.test(platform)) {
      browserOSCached = ClientOs.LINUX;
    } else if (/mac/i.test(platform)) {
      browserOSCached = ClientOs.MAC;
    } else if (/android/i.test(platform)) {
      browserOSCached = ClientOs.ANDROID;
    } else if (/ios/i.test(platform)) {
      browserOSCached = ClientOs.IOS;
    }
  }

  return browserOSCached;
}

enum PermissionAction {
  READ = "READ",
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
  ACK = "ACK",
  RESET = "RESET",
  CLEAR = "CLEAR",
  ACCESS = "ACCESS",
  INHERIT = "INHERIT",
}

export default PermissionAction;

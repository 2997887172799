import { PureAbility, Ability } from '@casl/ability';
import IAbilityHelper from './../IAbilityHelper';

export default class AbilityHelper implements IAbilityHelper {
  
  private ability = new PureAbility();//define((can) => {});

  public getAbility(): PureAbility {
    return this.ability;
  }

  public updateAbilities(rules: any): void {
    this.ability.update(rules)
  }

  public hasPermission(checkPermission: string, entity: string) : boolean {
    if (this.ability.can(checkPermission, entity)) return true;
    return false;
  }

  public hasOneOfPermissions(checkPermissions: string[], entity: string) : boolean {
    for (let i = 0; i < checkPermissions.length; i += 1) {
      if (this.hasPermission(checkPermissions[i], entity)) return true;
    }
    return false;
  }

  public hasPermissionOnOneOfEntities(checkPermission: string, entities: string[]) : boolean {
    for (let i = 0; i < entities.length; i += 1) {
      if (this.hasPermission(checkPermission, entities[i])) return true;
    }
    return false;
  }

  public hasPermissionOnAllEntities(checkPermission: string, entities: string[]) : boolean {
    for (let i = 0; i < entities.length; i += 1) {
      if (!this.hasPermission(checkPermission, entities[i])) return false;
    }
    return true;
  }
}

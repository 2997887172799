interface IConstants {
    HOST_NAME: string,
    REALM: string,
    APP_NAME: string,
    APP_VERSION: string,
    EDIT_MANIFEST_JSON_ENABLED: boolean,
    LANGUAGES_HOST: string,
}

export const constants: IConstants = {
    HOST_NAME: process.env.__HOST_NAME__,
    REALM: process.env.__REALM__,
    APP_NAME: process.env.__APP_NAME__,
    APP_VERSION: process.env.__APP_VERSION__,
    EDIT_MANIFEST_JSON_ENABLED: process.env.__EDIT_MANIFEST_JSON_ENABLED__ === 'true',
    LANGUAGES_HOST: process.env.__LANGUAGES_HOST__ || "local"
};
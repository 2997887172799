import {
    CommunicationSettings,
    CorvinaCoreAxiosInstance,
    DashboardAxiosInstance,
    DeviceMappingAxiosInstance,
    LicenseManagerAxiosInstance,
    AlarmsAxiosInstance,
    LimitsAxiosInstance,
    NotificationAxiosInstance,
    ProductsAxiosInstance,
    ThemeAxiosInstance,
    CorvinaPlatformControllerInstance,
  } from "../corvina-connect";

export function setCommunicationSettings( hostName: string, realm: string, i18nHost: string = "local") {
  CommunicationSettings.baseHostnameCache = hostName;
  CommunicationSettings.realmCache = realm;
  CommunicationSettings.i18nHost = i18nHost;

  // update axios instances
  CorvinaCoreAxiosInstance.updateBaseUrl();
  DashboardAxiosInstance.updateBaseUrl();
  DeviceMappingAxiosInstance.updateBaseUrl();
  LicenseManagerAxiosInstance.updateBaseUrl();
  AlarmsAxiosInstance.updateBaseUrl();
  LimitsAxiosInstance.updateBaseUrl();
  ProductsAxiosInstance.updateBaseUrl();
  NotificationAxiosInstance.updateBaseUrl();
  ThemeAxiosInstance.updateBaseUrl();
  CorvinaPlatformControllerInstance.updateBaseUrl();
}